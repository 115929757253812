@import "./reset";
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Bitter:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

$cream: rgb(248, 245, 230);

body {
  font-family: "Anonymous Pro", "Courier New", monospace;
  background-color: #131212;

  ::selection {
    background-color: #9b9b9b;
  }
}

#root {
  height: 100vh;
}

.App {
  height: 100%;
}

.home {
  height: 100%;
  max-height: calc(100% - 40px);
  max-width: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeCover {
  display: flex;
  justify-content: center;

  width: 350px;
  height: auto;
  min-height: 200px;
  min-width: 100px;

  max-width: calc(100vw - 50px);

  img {
    border: 1px solid $cream;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.menu {
  margin-top: 30px;

  .title {
    color: $cream;

    img {
      width: 450px;
      height: auto;
    }

    h3 {
      color: rgb(241, 229, 173);

      font-size: 3rem;
      margin: 10px 0px;
      cursor: default;
    }
  }

  .links {
    display: flex;
    justify-content: center;
    color: rgb(247, 243, 225);

    h3 {
      font-size: 4rem;
      font-weight: bold;
      cursor: default;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: white;
      }
    }
  }
}

.readTab {
  background-color: #1a1918;
  min-height: 100%;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: #2e2a2a;
    font-weight: bold;

    .elecsun-title {
      margin-left: 15px;
      display: flex;
      min-height: fit-content;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
      }
    }

    h2 {
      font-size: 1.5rem;
      margin: 15px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .arrowsinfo {
    display: none;
  }

  .readMain {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    min-height: 92vh;

    .poemList {
      overflow: auto;
      flex-basis: 18%;
      color: $cream;
      font-weight: 500;

      ul {
        padding: 10px;

        li {
          padding: 3px;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          &.selected {
            color: rgb(233, 215, 133);
          }
        }
      }
    }

    .book {
      flex-basis: 85%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #e6e6e6;
      font-family: "Bitter", serif;
      font-weight: 400;

      .page {
        line-height: 1.4rem;
        // animation: slidein 1s 1;
        max-width: 600px;
        height: 100%;

        .CharOverride-2 {
          font-size: 1.2rem;
          margin: 10px;
          line-height: 2.5rem;
          font-weight: bold;
        }

        width: 48%;
        margin: 10px;

        img {
          background-color: #e6e6e6;
          border: 1px solid #222222;
          height: auto;
          width: 100%;
        }
      }
    }

    .pagePrevious,
    .pageNext {
      color: #e6e6e6;
      position: relative;
      background: none;
      border: none;
      top: 200px;
      height: 100%;
      font-size: 3rem;
      //   font-weight: bold;
      z-index: 9999;

      margin: 10px;
      &:not(:disabled) {
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.endvideo {
  margin-top: 15px;
  display: flex;
  width: 300px;
  max-width: calc(100vw - 20px);

  video {
    max-width: 100%;
    max-height: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .menu {
    .title {
      img {
        max-width: calc(100vw - 20px);
      }

      h3 {
        font-size: 1.5rem;
        cursor: default;
      }
    }

    .links {
      h3 {
        font-size: 3rem;
        font-weight: bold;
        margin: 10px 0px;
        cursor: default;
        color: white;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .readTab {
    min-height: 100%;
    nav {
      .elecsun-title {
        h1 {
          font-size: 1.6rem;
          max-width: calc(100vw - 90px);
        }
      }

      h2 {
        font-size: 1.3rem;
      }
    }

    .arrowsinfo {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 15px 0px;
    }

    .readMain {
      padding-left: 8px;
      padding-right: 8px;
      margin: auto;
      background-color: #e6e6e6;

      .poemList {
        display: none;
        ul {
          // margin: 0px;
          padding: 5px;
          // li{

          // }
        }
        font-size: 0.6rem;
      }

      .arrowsHidden {
        opacity: 0;
      }

      .book {
        margin: auto;
        // margin-top: 10px;
        padding-bottom: 10px;
        flex-basis: 100%;
        max-width: calc(100vw - 20px);
        flex-direction: column;

        .page {
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.4rem;
          width: 100%;
          margin: 10px auto;
        }
      }
      .pagePrevious,
      .pageNext {
        background-color: #e6e6e6;
        color: black;
        transition: opacity 0.5s;
        height: auto;
        padding: 3px;
        border-radius: 5px;
        top: 48%;
        position: fixed;
        font-size: 2rem;
        margin: 5px;
      }

      .pagePrevious {
        left: 0px;
      }

      .pageNext {
        right: 0px;
      }
    }
  }
}
