/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Bitter:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-family: "Anonymous Pro", "Courier New", monospace;
  background-color: #131212;
}

body ::-moz-selection {
  background-color: #9b9b9b;
}

body ::selection {
  background-color: #9b9b9b;
}

#root {
  height: 100vh;
}

.App {
  height: 100%;
}

.home {
  height: 100%;
  max-height: calc(100% - 40px);
  max-width: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.homeCover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 350px;
  height: auto;
  min-height: 200px;
  min-width: 100px;
  max-width: calc(100vw - 50px);
}

.homeCover img {
  border: 1px solid #f8f5e6;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.menu {
  margin-top: 30px;
}

.menu .title {
  color: #f8f5e6;
}

.menu .title img {
  width: 450px;
  height: auto;
}

.menu .title h3 {
  color: #f1e5ad;
  font-size: 3rem;
  margin: 10px 0px;
  cursor: default;
}

.menu .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #f7f3e1;
}

.menu .links h3 {
  font-size: 4rem;
  font-weight: bold;
  cursor: default;
}

.menu .links h3:hover {
  cursor: pointer;
  text-decoration: underline;
  color: white;
}

.readTab {
  background-color: #1a1918;
  min-height: 100%;
}

.readTab nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: white;
  background-color: #2e2a2a;
  font-weight: bold;
}

.readTab nav .elecsun-title {
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.readTab nav .elecsun-title img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}

.readTab nav h2 {
  font-size: 1.5rem;
  margin: 15px;
}

.readTab nav h2:hover {
  cursor: pointer;
  text-decoration: underline;
}

.readTab .arrowsinfo {
  display: none;
}

.readTab .readMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  height: 100%;
  min-height: 92vh;
}

.readTab .readMain .poemList {
  overflow: auto;
  -ms-flex-preferred-size: 18%;
      flex-basis: 18%;
  color: #f8f5e6;
  font-weight: 500;
}

.readTab .readMain .poemList ul {
  padding: 10px;
}

.readTab .readMain .poemList ul li {
  padding: 3px;
}

.readTab .readMain .poemList ul li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.readTab .readMain .poemList ul li.selected {
  color: #e9d785;
}

.readTab .readMain .book {
  -ms-flex-preferred-size: 85%;
      flex-basis: 85%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #e6e6e6;
  font-family: "Bitter", serif;
  font-weight: 400;
}

.readTab .readMain .book .page {
  line-height: 1.4rem;
  max-width: 600px;
  height: 100%;
  width: 48%;
  margin: 10px;
}

.readTab .readMain .book .page .CharOverride-2 {
  font-size: 1.2rem;
  margin: 10px;
  line-height: 2.5rem;
  font-weight: bold;
}

.readTab .readMain .book .page img {
  background-color: #e6e6e6;
  border: 1px solid #222222;
  height: auto;
  width: 100%;
}

.readTab .readMain .pagePrevious,
.readTab .readMain .pageNext {
  color: #e6e6e6;
  position: relative;
  background: none;
  border: none;
  top: 200px;
  height: 100%;
  font-size: 3rem;
  z-index: 9999;
  margin: 10px;
}

.readTab .readMain .pagePrevious:not(:disabled):hover,
.readTab .readMain .pageNext:not(:disabled):hover {
  text-decoration: underline;
  cursor: pointer;
}

.readTab .readMain .pagePrevious:disabled,
.readTab .readMain .pageNext:disabled {
  opacity: 0.5;
}

.endvideo {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  max-width: calc(100vw - 20px);
}

.endvideo video {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 1024px) {
  .menu .title img {
    max-width: calc(100vw - 20px);
  }
  .menu .title h3 {
    font-size: 1.5rem;
    cursor: default;
  }
  .menu .links h3 {
    font-size: 3rem;
    font-weight: bold;
    margin: 10px 0px;
    cursor: default;
    color: white;
  }
  .menu .links h3:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .readTab {
    min-height: 100%;
  }
  .readTab nav .elecsun-title h1 {
    font-size: 1.6rem;
    max-width: calc(100vw - 90px);
  }
  .readTab nav h2 {
    font-size: 1.3rem;
  }
  .readTab .arrowsinfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    margin: 15px 0px;
  }
  .readTab .readMain {
    padding-left: 8px;
    padding-right: 8px;
    margin: auto;
    background-color: #e6e6e6;
  }
  .readTab .readMain .poemList {
    display: none;
    font-size: 0.6rem;
  }
  .readTab .readMain .poemList ul {
    padding: 5px;
  }
  .readTab .readMain .arrowsHidden {
    opacity: 0;
  }
  .readTab .readMain .book {
    margin: auto;
    padding-bottom: 10px;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: calc(100vw - 20px);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .readTab .readMain .book .page {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4rem;
    width: 100%;
    margin: 10px auto;
  }
  .readTab .readMain .pagePrevious,
  .readTab .readMain .pageNext {
    background-color: #e6e6e6;
    color: black;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    height: auto;
    padding: 3px;
    border-radius: 5px;
    top: 48%;
    position: fixed;
    font-size: 2rem;
    margin: 5px;
  }
  .readTab .readMain .pagePrevious {
    left: 0px;
  }
  .readTab .readMain .pageNext {
    right: 0px;
  }
}
